import './App.css';
import youtube from './images/youtube.svg';
import instagram from './images/instagram.svg';
import tiktok from './images/tiktok.svg';
import mail from './images/mail.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <h1>MinuteGardening.com</h1>
       <p>Coming soon</p>
       <div className="row m-t-2">
          <div className="col"><a href="https://www.youtube.com/channel/UC6-80FDA683P5kJXfVNwqXw/featured"><img src={youtube} alt="minutegardening" /></a></div>
          <div className="col p-x-2"><a href="https://www.instagram.com/minutegardening"><img src={instagram} alt="minutegardening" /></a></div>
          <div className="col p-r-2"><a href="https://www.tiktok.com/@minutegardening?lang=en"><img src={tiktok} alt="minutegardening" /></a></div>
          <div className="col"><a href="mailto:minutegardening@gmail.com"><img src={mail} alt="minutegardening" /></a></div>
      </div>
      </header>

    </div>
  );
}

export default App;
